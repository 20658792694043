import { FlowbasesActionTypes } from './constants';

type FlowbasesAction = { type: string, payload: {} | string };

// common success
export const flowbasesApiResponseSuccess = (actionType: string, data: any): FlowbasesAction => ({
    type: FlowbasesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const flowbasesApiResponseError = (actionType: string, error: string): FlowbasesAction => ({
    type: FlowbasesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getFlowbases = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.GET_FLOWBASES,
    payload: payload,
});

export const getFlowbasesPids = (): FlowbasesAction => ({
    type: FlowbasesActionTypes.GET_PIDS,
});

export const createFlowbase = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.CREATE_FLOWBASE,
    payload: payload,
});

export const getUnloadFlowPids = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS,
    payload: payload,
});

export const getUnloadFlowPidInfo = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO,
    payload: payload,
});

export const unloadFlowPid = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.UNLOAD_FLOW_PID,
    payload: payload,
});

export const setCurrentFlowbase = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.SET_CURRENT_FLOWBASE,
    payload: payload,
});

export const renameFlowbase = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.RENAME_FLOWBASE,
    payload: payload,
});

export const setFlowbasesFilter = (payload): FlowbasesAction => ({
    type: FlowbasesActionTypes.SET_FILTER,
    payload: payload,
});
