// @flow
import { AdminActionTypes } from './constants';

// const api = new APICore();

const INIT_STATE = {
    users: [],
    loading: false,
};

type AdminAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Admin = (state: State = INIT_STATE, action: AdminAction): any => {
    switch (action.type) {
        case AdminActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AdminActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.data,
                        loading: false,
                    };
                }
                case AdminActionTypes.CREATE_USER: {
                    alert('create user payload ' + JSON.stringify(action.payload));
                    return {
                        ...state,
                        users: [...state.users, action.payload.data],
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AdminActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AdminActionTypes.GET_USERS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case AdminActionTypes.CREATE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AdminActionTypes.GET_USERS:
            return { ...state, loading: true };
        case AdminActionTypes.CREATE_USER:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export default Admin;
