import { ArchiveActionTypes } from './constants';

type StatsAction = { type: string, payload: {} | string };

// common success
export const archiveApiResponseSuccess = (actionType: string, data: any): StatsAction => ({
    type: ArchiveActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const archiveApiResponseError = (actionType: string, error: string): StatsAction => ({
    type: ArchiveActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getArchive = (searchParams): StatsAction => ({
    type: ArchiveActionTypes.GET_ARCHIVE,
    payload: searchParams,
});
