export const FlowbasesActionTypes = {
    API_RESPONSE_SUCCESS: '@@flowbases/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@flowbases/API_RESPONSE_ERROR',

    GET_FLOWBASES: '@@flowbases/GET_FLOWBASES',
    GET_PIDS: '@@flowbases/GET_PIDS',
    GET_UNLOAD_FLOW_PIDS: '@@flowbases/GET_UNLOAD_FLOW_PIDS',
    GET_UNLOAD_FLOW_PID_INFO: '@@flowbases/GET_UNLOAD_FLOW_PID_INFO',
    UNLOAD_FLOW_PID: '@@flowbases/UNLOAD_FLOW_PID',

    CREATE_FLOWBASE: '@@flowbases/CREATE_FLOWBASE',
    RESET_CREATE_ERROR: '@@flowbases/RESET_CREATE_ERROR',
    SET_CURRENT_FLOWBASE: '@@flowbases/SET_CURRENT_FLOWBASE',
    RENAME_FLOWBASE: '@@flowbases/RENAME_FLOWBASE',
    SET_FILTER: '@@flowbases/SET_FILTER',
};
