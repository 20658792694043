import { BasesActionTypes } from './constants';

const INIT_STATE = {
    pageCount: 0,
    bases: [],
    currentBase: null,
    loading: false,
    uploadingError: '',
    basesFilter: {},
};

type BasesAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean, uploadingError: string };

const Bases = (state: State = INIT_STATE, action: BasesAction): any => {
    switch (action.type) {
        case BasesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BasesActionTypes.GET_BASES: {
                    return {
                        ...state,
                        pageCount: action.payload?.data?.pageCount,
                        bases: action.payload?.data?.basesList,
                        loading: false,
                    };
                }
                case BasesActionTypes.CREATE_BASE:
                case BasesActionTypes.RENAME_BASE:
                    return {
                        ...state,
                        loading: false,
                    };

                default:
                    return { ...state };
            }

        case BasesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BasesActionTypes.GET_BASES:
                case BasesActionTypes.RENAME_BASE:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                case BasesActionTypes.CREATE_BASE: {
                    return {
                        ...state,
                        uploadingError: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BasesActionTypes.GET_BASES:
        case BasesActionTypes.CREATE_BASE:
        case BasesActionTypes.RENAME_BASE:
            return {
                ...state,
                loading: true,
            };
        case BasesActionTypes.SET_CURRENT_BASE:
            return {
                ...state,
                currentBase: action.payload,
            };
        case BasesActionTypes.SET_FILTER:
            return {
                ...state,
                basesFilter: action.payload,
            };
        default:
            return { ...state };
    }
};

export default Bases;
