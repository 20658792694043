import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import adminSaga from './admin/saga';
import smsSendingsSaga from './smsSendings/saga';
import basesSaga from './bases/saga';
import agentsSaga from './agents/saga';
import flowbasesSaga from './flowbases/saga';
import blacklistsSaga from './blacklists/saga';
import notificationsSaga from './notifications/saga';
import statsSaga from './stats/saga';
import archiveSaga from './archive/saga';

export default function* rootSaga(): any {
    yield all([
        authSaga(),
        layoutSaga(),
        adminSaga(),
        smsSendingsSaga(),
        basesSaga(),
        agentsSaga(),
        flowbasesSaga(),
        blacklistsSaga(),
        notificationsSaga(),
        statsSaga(),
        archiveSaga(),
    ]);
}
