// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ArchiveActionTypes } from './constants';
import { getSmsSendings as getSmsSendingsApi } from '../../helpers/';
import { archiveApiResponseError, archiveApiResponseSuccess } from './actions';
import { statuses } from '../../pages/smska/sms/constants';
import { fromTimestampStringToDateString } from '../../utils/moment';

function* getArchive(action) {
    try {
        const response = yield call(getSmsSendingsApi, action.payload);
        const { sendingList, totalCount } = response.data.sms;
        sendingList.forEach((sendingItem) => {
            sendingItem.status = statuses[sendingItem.status];
            sendingItem.date_start = fromTimestampStringToDateString(sendingItem.date_start);
            sendingItem.date_end = fromTimestampStringToDateString(sendingItem.date_end);
        });
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        yield put(archiveApiResponseSuccess(ArchiveActionTypes.GET_ARCHIVE, { archive: sendingList, pageCount }));
    } catch (error) {
        yield put(archiveApiResponseError(ArchiveActionTypes.GET_ARCHIVE, error));
    }
}

export function* watchGetArchive(): any {
    yield takeEvery(ArchiveActionTypes.GET_ARCHIVE, getArchive);
}

function* archiveSaga(): any {
    yield all([fork(watchGetArchive)]);
}

export default archiveSaga;
