export const BasesActionTypes = {
    API_RESPONSE_SUCCESS: '@@bases/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@bases/API_RESPONSE_ERROR',

    GET_BASES: '@@bases/GET_BASES',
    CREATE_BASE: '@@bases/CREATE_BASE',
    SET_CURRENT_BASE: '@@bases/SET_CURRENT_BASE',
    RENAME_BASE: '@@bases/RENAME_BASE',
    SET_FILTER: '@@bases/SET_FILTER',
};
