// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Admin from './admin/reducers';
import SmsSendings from './smsSendings/reducers';
import Bases from './bases/reducers';
import Agents from './agents/reducers';
import Flowbases from './flowbases/reducers';
import Blacklists from './blacklists/reducers';
import Notifications from './notifications/reducers';
import Stats from './stats/reducers';
import Archive from './archive/reducers';

export default (combineReducers({
    Admin,
    Auth,
    Layout,
    SmsSendings,
    Bases,
    Agents,
    Flowbases,
    Blacklists,
    Notifications,
    Stats,
    Archive,
}): any);
