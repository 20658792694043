import { all, delay, fork, put, takeEvery } from 'redux-saga/effects';
import { removeNotification as removeNotificationApi } from './actions';
import { NotificationsActionTypes } from './constants';

function* addNotification(action) {
    const { id, timeout } = action.payload;
    yield delay(timeout || 5000);
    yield put(removeNotificationApi(id));
}

export function* watchAddNotifications() {
    yield takeEvery(NotificationsActionTypes.ADD_NOTIFICATION, addNotification);
}

function* notificationsSaga() {
    yield all([fork(watchAddNotifications)]);
}

export default notificationsSaga;
