import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { BasesActionTypes } from './constants';
import { createBase as createBaseApi, getBases as getBasesApi, renameBase as renameBaseApi } from '../../helpers/';
import { basesApiResponseError, basesApiResponseSuccess, getBases as getBasesAction } from './actions';
import { addNotification } from '../notifications/actions';

function* getBases(action) {
    try {
        const response = yield call(getBasesApi, action.payload);
        // mapper
        const { totalCount, basesList } = response.data?.bases;
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        yield put(basesApiResponseSuccess(BasesActionTypes.GET_BASES, { basesList, pageCount }));
    } catch (error) {
        yield put(basesApiResponseError(BasesActionTypes.GET_BASES, error));
    }
}

function* createBase(action) {
    try {
        yield call(createBaseApi, action.payload);
        yield put(basesApiResponseSuccess(BasesActionTypes.CREATE_BASE));
        yield put(
            addNotification({
                message: 'Новая база создана',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(basesApiResponseError(BasesActionTypes.CREATE_BASE, error));
        yield put(
            addNotification({
                message: 'Не удалось создать базу',
                variant: 'danger',
            })
        );
    }
}

function* renameBase(action) {
    try {
        const response = yield call(renameBaseApi, action.payload);
        yield put(basesApiResponseSuccess(BasesActionTypes.RENAME_BASE));
        if (response.data.status === 'OK') {
            const filter = yield select((state) => state.Bases.basesFilter);
            yield put(getBasesAction(filter));
            yield put(
                addNotification({
                    message: 'База переименована',
                    variant: 'success',
                })
            );
        }
    } catch (error) {
        yield put(basesApiResponseError(BasesActionTypes.RENAME_BASE, error));
        yield put(
            addNotification({
                message: 'Не удалось переименовать базу',
                variant: 'danger',
            })
        );
    }
}

export function* watchGetBases(): any {
    yield takeEvery(BasesActionTypes.GET_BASES, getBases);
}

export function* watchCreateBase(): any {
    yield takeEvery(BasesActionTypes.CREATE_BASE, createBase);
}

export function* watchRenameBase(): any {
    yield takeEvery(BasesActionTypes.RENAME_BASE, renameBase);
}

function* BasesSaga(): any {
    yield all([fork(watchGetBases), fork(watchCreateBase), fork(watchRenameBase)]);
}

export default BasesSaga;
