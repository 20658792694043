// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { StatsActionTypes } from './constants';
import { downloadStats as downloadStatsApi, getStats as getStatsApi } from '../../helpers/';
import { statsApiResponseError, statsApiResponseSuccess } from './actions';
import { addNotification } from '../notifications/actions';

function* getStats(action) {
    try {
        const response = yield call(getStatsApi, action.payload);
        const { statsList, totalCount, totalValues } = response.data.stats;
        statsList.forEach((statItem) => {
            statItem._id = statItem._id?.sms;
        });
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        const totals = {
            user: 'Итого',
            ...totalValues,
        };
        yield put(statsApiResponseSuccess(StatsActionTypes.GET_STATS, { statsList, pageCount, totals }));
    } catch (error) {
        yield put(statsApiResponseError(StatsActionTypes.GET_STATS, error));
    }
}

function* downloadStats(action) {
    try {
        const response = yield call(downloadStatsApi, action.payload);
        const fileName = response.headers['content-disposition']?.split('filename=')?.[1] || 'stats.xlsx';
        const linkUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = linkUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        yield put(statsApiResponseSuccess(StatsActionTypes.DOWNLOAD_STATS, {}));
        yield put(
            addNotification({
                message: 'Файл успешно скачан',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(statsApiResponseError(StatsActionTypes.DOWNLOAD_STATS, error));
        yield put(
            addNotification({
                message: 'Не удалось скачать файл',
                variant: 'danger',
            })
        );
    }
}

export function* watchGetStats(): any {
    yield takeEvery(StatsActionTypes.GET_STATS, getStats);
}

export function* watchDownloadStats(): any {
    yield takeEvery(StatsActionTypes.DOWNLOAD_STATS, downloadStats);
}

function* statsSaga(): any {
    yield all([fork(watchGetStats), fork(watchDownloadStats)]);
}

export default statsSaga;
