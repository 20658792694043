import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/flowbases/';

function getFlowbases(params: any): any {
    return api.get(`${baseUrl}`, params);
}
function getPids(params: any): any {
    return api.get(`${baseUrl}pids`, params);
}

function createFlowbase(data: any): any {
    return api.create(`${baseUrl}`, data);
}

function getUnloadFlowPids(): any {
    return api.get(`${baseUrl}flow-pids`);
}

function getUnloadFlowPidInfo(params: any): any {
    const { startDate, endDate, pid } = params;
    const requestParams = {
        pid: pid[0]?.id || '',
        unload: false,
        date: { from: startDate, to: endDate },
    };
    return api.getWithFilter(`${baseUrl}unload-flow-pids-info`, requestParams);
}

function unloadFlowPid(params: any): any {
    const { startDate, endDate, pid } = params;
    const requestParams = {
        pid: pid[0]?.id || '',
        unload: true,
        date: { from: startDate, to: endDate },
    };
    return api.getWithFilter(`${baseUrl}unload-flow-pid`, requestParams);
}

function renameFlowbase(params: any): any {
    return api.updatePatch(`${baseUrl}`, params);
}

export {
    getFlowbases,
    getPids,
    createFlowbase,
    getUnloadFlowPids,
    getUnloadFlowPidInfo,
    unloadFlowPid,
    renameFlowbase,
};
