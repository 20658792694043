// @flow
import { ArchiveActionTypes } from './constants';

const INIT_STATE = {
    archive: [],
    loading: false,
    pageCount: 0,
    error: null,
};

type ArchiveAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Archive = (state: State = INIT_STATE, action: ArchiveAction): any => {
    switch (action.type) {
        case ArchiveActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ArchiveActionTypes.GET_ARCHIVE: {
                    return {
                        ...state,
                        pageCount: action.payload?.data?.pageCount,
                        archive: action.payload?.data?.archive,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ArchiveActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ArchiveActionTypes.GET_ARCHIVE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ArchiveActionTypes.GET_ARCHIVE:
            return {
                ...state,
                stats: INIT_STATE.archive,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default Archive;
