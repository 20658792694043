import { FlowbasesActionTypes } from './constants';

const INIT_STATE = {
    pageCount: 0,
    flowbases: [],
    pids: [],
    unloadFlowPids: [],
    unloadFlowPidInfo: {},
    currentFlowbase: null,
    loading: false,
    creatingError: '',
    flowbasesFilter: {},
};

type FlowbasesAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Flowbases = (state: State = INIT_STATE, action: FlowbasesAction): any => {
    switch (action.type) {
        case FlowbasesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FlowbasesActionTypes.GET_FLOWBASES: {
                    return {
                        ...state,
                        flowbases: action.payload?.data?.flowbases,
                        loading: false,
                    };
                }
                case FlowbasesActionTypes.GET_PIDS: {
                    return {
                        ...state,
                        pids: action.payload?.data?.pids,
                        loading: false,
                    };
                }
                case FlowbasesActionTypes.CREATE_FLOWBASE: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS:
                    return {
                        ...state,
                        loading: false,
                        unloadFlowPids: action.payload?.data,
                    };
                case FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO:
                    return {
                        ...state,
                        loading: false,
                        unloadFlowPidInfo: action.payload?.data,
                    };
                case FlowbasesActionTypes.UNLOAD_FLOW_PID:
                case FlowbasesActionTypes.RENAME_FLOWBASE:
                    return {
                        ...state,
                        loading: false,
                    };
                default:
                    return { ...state };
            }

        case FlowbasesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FlowbasesActionTypes.GET_PIDS:
                case FlowbasesActionTypes.GET_FLOWBASES:
                case FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS:
                case FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO:
                case FlowbasesActionTypes.UNLOAD_FLOW_PID:
                case FlowbasesActionTypes.RENAME_FLOWBASE:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                case FlowbasesActionTypes.CREATE_FLOWBASE: {
                    return {
                        ...state,
                        creatingError: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case FlowbasesActionTypes.GET_FLOWBASES:
        case FlowbasesActionTypes.GET_PIDS:
        case FlowbasesActionTypes.CREATE_FLOWBASE:
        case FlowbasesActionTypes.UNLOAD_FLOW_PID:
        case FlowbasesActionTypes.RENAME_FLOWBASE:
            return {
                ...state,
                loading: true,
            };
        case FlowbasesActionTypes.RESET_CREATE_ERROR:
            return {
                ...state,
                creatingError: INIT_STATE.creatingError,
            };
        case FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS:
            return {
                ...state,
                loading: true,
                unloadFlowPids: INIT_STATE.unloadFlowPids,
            };
        case FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO:
            return {
                ...state,
                loading: true,
                unloadFlowPidInfo: INIT_STATE.unloadFlowPidInfo,
            };
        case FlowbasesActionTypes.SET_CURRENT_FLOWBASE:
            return {
                ...state,
                currentFlowbase: action.payload,
            };
        case FlowbasesActionTypes.SET_FILTER:
            return {
                ...state,
                flowbasesFilter: action.payload,
            };
        default:
            return { ...state };
    }
};

export default Flowbases;
