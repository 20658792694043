import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/stats/';

function getStats(params: any): any {
    const [from, to] = params?.filter?.date;
    const requestParams = {
        ...params,
        filter: {
            ...params.filter,
            date: { from, to },
        },
    };
    return api.getWithFilter(`${baseUrl}`, requestParams);
}

function downloadStats(params: any): any {
    const [from, to] = params?.filter?.date;
    const requestParams = {
        ...params,
        filter: {
            ...params.filter,
            date: { from, to },
        },
    };
    return api.getFileWithFilter(`${baseUrl}excel`, requestParams);
}

export { getStats, downloadStats };
