export const SmsSendingsActionTypes = {
    API_RESPONSE_SUCCESS: '@@smsSendings/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@smsSendings/API_RESPONSE_ERROR',

    GET_SENDINGS: '@@smsSendings/GET_SENDINGS',
    SEND_TO_ARCHIVE: '@@smsSendings/SEND_TO_ARCHIVE',
    GET_ABOUT_SENDING: '@@smsSendings/GET_ABOUT_SENDING',
    SET_SENDING_TO_NEW: '@@smsSendings/SET_SENDING_TO_NEW',
    UPDATE_STATUS: '@@smsSendings/UPDATE_STATUS',
    CREATE_SENDING: '@@smsSendings/CREATE_SENDING',
    UPDATE_SENDING: '@@smsSendings/UPDATE_SENDING',
    RESET_SUCCESSFULL_STATE: '@@smsSendings/RESET_SUCCESSFULL_STATE',
    RENAME_SENDING: '@@smsSendings/RENAME_SENDING',
    CHANGE_AGENT: '@@smsSendings/CHANGE_AGENT',
    SET_FILTER: '@@smsSendings/SET_FILTER',
};
