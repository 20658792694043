import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { FlowbasesActionTypes } from './constants';
import {
    createFlowbase as createFlowbaseApi,
    getFlowbases as getFlowbasesApi,
    getPids as getPidsApi,
    getUnloadFlowPidInfo as getUnloadFlowPidInfoApi,
    getUnloadFlowPids as getUnloadFlowPidsApi,
    renameFlowbase as renameFlowbaseApi,
    unloadFlowPid as unloadFlowPidApi,
} from '../../helpers/';
import { flowbasesApiResponseError, flowbasesApiResponseSuccess, getFlowbases as getFlowbasesAction } from './actions';
import { addNotification } from '../notifications/actions';

function* getFlowbases(action) {
    try {
        const response = yield call(getFlowbasesApi, action.payload);
        const { flowbases } = response.data;
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.GET_FLOWBASES, { flowbases }));
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.GET_FLOWBASES, error));
    }
}

function* getPids(action) {
    try {
        const response = yield call(getPidsApi, action.payload);
        const { pids } = response.data;
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.GET_PIDS, { pids }));
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.GET_PIDS, error));
    }
}

function* createFlowbase(action) {
    try {
        yield call(createFlowbaseApi, action.payload);
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.CREATE_FLOWBASE));
        yield put(
            addNotification({
                message: 'Новая потоковая база создана',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.CREATE_FLOWBASE, error));
        yield put(
            addNotification({
                message: 'Не удалось создать потоковую базу',
                variant: 'danger',
            })
        );
    }
}

function* getUnloadFlowPids(action) {
    try {
        const response = yield call(getUnloadFlowPidsApi, action.payload);
        const { unloadPids } = response.data;
        console.log('getUnloadFlowPids', response);
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS, unloadPids));
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS, error));
        yield put(
            addNotification({
                message: 'Не удалось загрузить потоки',
                variant: 'danger',
            })
        );
    }
}

function* getUnloadFlowPidInfo(action) {
    try {
        const response = yield call(getUnloadFlowPidInfoApi, action.payload);
        const { pidInfo } = response.data;
        console.log('getUnloadFlowPidInfo', response);
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO, pidInfo));
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO, error));
        yield put(
            addNotification({
                message: 'Не удалось подсчитать',
                variant: 'danger',
            })
        );
    }
}

function* unloadFlowPid(action) {
    try {
        const response = yield call(unloadFlowPidApi, action.payload);
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.UNLOAD_FLOW_PID, response.data));
        yield put(
            addNotification({
                message: 'Поток выгружен',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.UNLOAD_FLOW_PID, error));
        yield put(
            addNotification({
                message: 'Не удалось выгрузить поток',
                variant: 'danger',
            })
        );
    }
}

function* renameFlowbase(action) {
    try {
        const response = yield call(renameFlowbaseApi, action.payload);
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.RENAME_FLOWBASE));
        if (response.data.status === 'OK') {
            const filter = yield select((state) => state.Flowbases.flowbasesFilter);
            yield put(getFlowbasesAction(filter));
            yield put(
                addNotification({
                    message: 'Поток переименован',
                    variant: 'success',
                })
            );
        }
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.RENAME_FLOWBASE, error));
        yield put(
            addNotification({
                message: 'Не удалось переименовать поток',
                variant: 'danger',
            })
        );
    }
}

export function* watchGetFlowbases(): any {
    yield takeEvery(FlowbasesActionTypes.GET_FLOWBASES, getFlowbases);
}

export function* watchGetPids(): any {
    yield takeEvery(FlowbasesActionTypes.GET_PIDS, getPids);
}

export function* watchCreateFlowbase(): any {
    yield takeEvery(FlowbasesActionTypes.CREATE_FLOWBASE, createFlowbase);
}

export function* watchGetUnloadFlowPids(): any {
    yield takeEvery(FlowbasesActionTypes.GET_UNLOAD_FLOW_PIDS, getUnloadFlowPids);
}

export function* watchGetUnloadFlowPidInfo(): any {
    yield takeEvery(FlowbasesActionTypes.GET_UNLOAD_FLOW_PID_INFO, getUnloadFlowPidInfo);
}

export function* watchUnloadFlowPid(): any {
    yield takeEvery(FlowbasesActionTypes.UNLOAD_FLOW_PID, unloadFlowPid);
}

export function* watchRenameFlowbase(): any {
    yield takeEvery(FlowbasesActionTypes.RENAME_FLOWBASE, renameFlowbase);
}

function* flowbasesSaga(): any {
    yield all([
        fork(watchGetFlowbases),
        fork(watchGetPids),
        fork(watchCreateFlowbase),
        fork(watchGetUnloadFlowPids),
        fork(watchGetUnloadFlowPidInfo),
        fork(watchUnloadFlowPid),
        fork(watchRenameFlowbase),
    ]);
}

export default flowbasesSaga;
