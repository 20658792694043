import { StatsActionTypes } from './constants';

type StatsAction = { type: string, payload: {} | string };

// common success
export const statsApiResponseSuccess = (actionType: string, data: any): StatsAction => ({
    type: StatsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const statsApiResponseError = (actionType: string, error: string): StatsAction => ({
    type: StatsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getStats = (searchParams): StatsAction => ({
    type: StatsActionTypes.GET_STATS,
    payload: searchParams,
});

export const downloadStats = (searchParams): StatsAction => ({
    type: StatsActionTypes.DOWNLOAD_STATS,
    payload: searchParams,
});
