import { NotificationsActionTypes } from './constants';

type NotificationsAction = { type: string, payload: {} | string };

export const addNotification = (notification): NotificationsAction => ({
    type: NotificationsActionTypes.ADD_NOTIFICATION,
    payload: notification,
});

export const removeNotification = (id): NotificationsAction => ({
    type: NotificationsActionTypes.REMOVE_NOTIFICATION,
    payload: id,
});
