import { BasesActionTypes } from './constants';

type BasesAction = { type: string, payload: {} | string };

// common success
export const basesApiResponseSuccess = (actionType: string, data: any): BasesAction => ({
    type: BasesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const basesApiResponseError = (actionType: string, error: string): BasesAction => ({
    type: BasesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getBases = (payload): BasesAction => ({
    type: BasesActionTypes.GET_BASES,
    payload: payload,
});

export const createBase = (payload): BasesAction => ({
    type: BasesActionTypes.CREATE_BASE,
    payload: payload,
});

export const setCurrentBase = (payload): BasesAction => ({
    type: BasesActionTypes.SET_CURRENT_BASE,
    payload: payload,
});

export const renameBase = (payload): BasesAction => ({
    type: BasesActionTypes.RENAME_BASE,
    payload: payload,
});

export const setBasesFilter = (payload): BasesAction => ({
    type: BasesActionTypes.SET_FILTER,
    payload: payload,
});
