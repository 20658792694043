import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AgentsActionTypes } from './constants';
import {
    createAgent as createAgentApi,
    getAgents as getAgentsApi,
    updateAgent as updateAgentApi,
} from '../../helpers/';
import { agentsApiResponseError, agentsApiResponseSuccess } from './actions';
import { addNotification } from '../notifications/actions';

function* getAgents(action) {
    try {
        const response = yield call(getAgentsApi, action.payload);
        // mapper
        const { agents } = response.data;
        // const pageCount = Math.ceil(totalCount / action.payload?.limit);
        yield put(agentsApiResponseSuccess(AgentsActionTypes.GET_AGENTS, { agents }));
    } catch (error) {
        yield put(agentsApiResponseError(AgentsActionTypes.GET_AGENTS, error));
    }
}

function* createAgent(action) {
    try {
        yield call(createAgentApi, action.payload);
        yield put(agentsApiResponseSuccess(AgentsActionTypes.CREATE_AGENT));
        yield put(
            addNotification({
                message: 'Новый агент создан',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(agentsApiResponseError(AgentsActionTypes.CREATE_AGENT, error));
        yield put(
            addNotification({
                message: 'Не удалось создать агента',
                variant: 'danger',
            })
        );
    }
}

function* updateAgent(action) {
    try {
        yield call(updateAgentApi, action.payload);
        yield put(agentsApiResponseSuccess(AgentsActionTypes.UPDATE_AGENT));
    } catch (error) {
        yield put(agentsApiResponseError(AgentsActionTypes.UPDATE_AGENT, error));
    }
}

export function* watchGetAgents(): any {
    yield takeEvery(AgentsActionTypes.GET_AGENTS, getAgents);
}

export function* watchCreateAgent(): any {
    yield takeEvery(AgentsActionTypes.CREATE_AGENT, createAgent);
}

export function* watchUpdateAgent(): any {
    yield takeEvery(AgentsActionTypes.UPDATE_AGENT, updateAgent);
}

function* agentsSaga(): any {
    yield all([fork(watchGetAgents), fork(watchCreateAgent), fork(watchUpdateAgent)]);
}

export default agentsSaga;
